import React from "react";
import BG from "../../../images/get-started.png";
import BG_mob from "../../../images/report-mob.png";
import { Styles } from "../../../styles/ReportStyles";

function GetStarted(props) {
	const style = Styles();
	return (
		<div className="container" style={{ marginTop: "20px" }}>
			<div className="row">
				<div className="col-sm-12">
					<div className={style.getStarted + " row"}>
						<h2 className={style.h2}>Speak to our team to get this look</h2>
						<img
							src={BG}
							alt="Get Started"
							style={{ width: "100%" }}
							className={style.desktop}
						/>
						<img
							src={BG_mob}
							alt="Get Started"
							style={{ width: "100%", padding: "0px 15px" }}
							className={style.mobile}
						/>
						<div className={style.headingsGetStarted}>
							<h2 className={style.headingh2}>
								Get the designer home you have always wanted!
							</h2>
							<div className={style.btns}>
								<a
									href="https://www.beautifulhomes.com/interior-design-services.html"
									className={style.hyperlink}
									rel="noreferrer"
									target="_blank"
								>
									GET STARTED
								</a>
								<a
									href="https://www.beautifulhomes.com/interior-design-services/emi.html"
									className={style.hyperlink}
									rel="noreferrer"
									target="_blank"
								>
									EMI OPTIONS
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GetStarted;
