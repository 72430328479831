import React from "react";
import Buttons from "../Buttons/Buttons";
import Checkbox from "@material-ui/core/Checkbox";
import Slide from "@material-ui/core/Slide";
import { Styles } from "../../../styles/TextRadio";
import checkedImage from "../../../images/checked.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const alphabetOptions = [
	require("../../../images/a.png").default,
	require("../../../images/b.png").default,
	require("../../../images/c.png").default,
	require("../../../images/d.png").default,
	require("../../../images/e.png").default,
];

function TextCheckbox(props) {
	const {
		checkboxData,
		onCheckBoxChange,
		index,
		questionData,
		selectedCount,
	} = props;
	const style = Styles();

	return (
		<>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 500 } : {})}
			>
				<div className="col-sm-4">
					<span className={style.span}>
						Question
						<span className={style.questionCount}>
							{index + 1} / {questionData.length}
						</span>
					</span>
					<h1
						className={style.h1}
						dangerouslySetInnerHTML={{ __html: checkboxData.question }}
					></h1>
					<p
						className={style.p}
						dangerouslySetInnerHTML={{ __html: checkboxData.subhead }}
					></p>
					<div>
						<Buttons style={style} btnData={checkboxData} {...props} />
					</div>
				</div>
			</Slide>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 1000 } : {})}
			>
				<div className="col-sm-8">
					<div className="row">
						{checkboxData.options.map((elem, i) => (
							<FormControlLabel
								key={elem.id}
								className={style.radioTextLabel}
								disabled={
									selectedCount === checkboxData.min
										? !elem.checked
											? true
											: false
										: ""
								}
								control={
									<Checkbox
										value={elem.id}
										checked={elem.checked}
										className={style.root}
										onChange={onCheckBoxChange}
									/>
								}
								label={
									<div
										className={
											elem.checked
												? style.textButtonAfterSelect
												: style.textButton
										}
									>
										{elem.checked ? (
											<img
												src={checkedImage}
												alt="Checked Option"
												className={style.imageSelected}
											/>
										) : (
											""
										)}
										<img src={alphabetOptions[i]} alt="Checkbox Text" />
										<h4 className={style.h4}>{elem.option}</h4>
									</div>
								}
							/>
						))}
					</div>
				</div>
			</Slide>
		</>
	);
}

export default TextCheckbox;
