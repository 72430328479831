import Axios from "axios";
// import adobe from "../../at";
import Radio from "./RadioButton/Radio";
import { Link } from "react-router-dom";
import Checkbox from "./Checkbox/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import config from "../../services/config.json";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import QuestionsHeader from "../../common/QuestionsHeader";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

const Styles = makeStyles((theme) => ({
	main: {
		marginTop: "1rem",
		overflow: "hidden",
	},
	loader: {
		position: "absolute",
		top: "44%",
		left: "50%",
	},
	modal: {
		"& .MuiButtonBase-root": {
			outline: "none",
			[theme.breakpoints.down("xs")]: {
				marginLeft: -15,
			},
		},
		"& .MuiButton-label": {
			outline: "none",
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 11,
			},
		},
		"& .MuiDialogTitle-root": {
			paddingLeft: "0px",
		},
		"& .MuiTypography-root": {
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 13,
			},
		},
		"& .MuiDialogContentText-root": {
			color: "#818181",
			fontFamily: "NunitoSans-Regular",
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
		"& .MuiDialog-paper": {
			borderBottom: "2px solid #ed1c24",
		},
	},
}));

let likeindex = 0;
localStorage.setItem("backClicked", false);

function Quiz(props) {
	// Code by Pushpanjali
	const style = Styles();
	let history = useHistory();
	const clickRef = React.useRef();
	const [data, setData] = useState([]);
	const [index, setIndex] = useState(0);
	const [open, setOpen] = useState(false);
	const [openup, setOpenup] = useState(true);
	const [isPrevious, setisPrevious] = useState(false);
	const [lastQuestion, setlastQuestion] = useState(false);

	useEffect(() => {
		// const cancelTokenSource = Axios.CancelToken.source();
		// const cancelTokenSource = Axios.CancelToken.source();
		async function getData() {
			const response = await Axios.get(config.question_api, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			});
			setData(response.data.data);
			const result = response.data.data;
			setData(result);
		}
		var dummyUrl = { foo: true };
		history.push(dummyUrl, "unused argument", "#newInitialUri");
		var bar = { bar: true };
		history.push(bar, "unused argument", "#newStateOfWebApp");

		document.body.style.backgroundColor = "#f8f4f4";
		getData();
		// return cancelTokenSource.cancel();
	}, []);

	window.onpopstate = function (event) {
		localStorage.setItem("backClicked", true);
		var fooUrl = { baz: true };
		history.push(fooUrl, "unused argument", "#baseState");
		setOpenup(true);
	};

	const handleCheckboxChange = (e) => {
		const copyData = { ...data };
		const dataObj = copyData.question_json[index];
		const availableOptions = dataObj.options;
		const val = availableOptions.map((item) => {
			if (item.id === parseInt(e.target.value) && item.checked === false) {
				item.checked = true;
				item.score = 1;
				dataObj.count += 1;
			} else if (
				item.id === parseInt(e.target.value) &&
				item.checked === true
			) {
				item.checked = false;
				item.score = 0;
				dataObj.count -= 1;
			}

			return item;
		});

		dataObj.options = val;
		copyData.question_json[index] = dataObj;
		setData(copyData);
	};

	const handleRadioBtnChange = (e) => {
		const copyData = { ...data };
		const dataObj = copyData.question_json[index];
		const availableOptions = dataObj.options;

		const val = availableOptions.map((item) => {
			if (item.id === parseInt(e.target.value) && item.checked === false) {
				item.checked = true;
				item.score = 1;
				dataObj.count += 1;
			} else {
				item.checked = false;
				item.score = 0;
				dataObj.count += 1;
			}

			return item;
		});

		dataObj.options = val;
		copyData.question_json[index] = dataObj;
		setData(copyData);
	};

	const handleLDChange = (e) => {
		const copyData = { ...data };
		const dataObj = copyData.question_json[index];
		const availableOptions = dataObj.options;

		if (availableOptions.length === likeindex) {
			likeindex = 0;
		}

		const val = availableOptions.map((elem) => {
			if (e.target.value === "like") {
				if (elem.id === parseInt(e.target.id)) {
					elem.checked = true;
					elem.likeChecked = true;
					elem.dislikeChecked = false;
					elem.score = 1;
					dataObj.count += 1;
					localStorage.setItem("lastQuestion", false);
				}
			}
			if (e.target.value === "dislike") {
				if (elem.id === parseInt(e.target.id)) {
					elem.checked = true;
					elem.dislikeChecked = true;
					elem.likeChecked = false;
					elem.score = -1;
					dataObj.count += 1;
					localStorage.setItem("lastQuestion", false);
				}
			}

			return elem;
		});

		const list = document.getElementsByClassName("MuiButtonBase-root");
		for (let item of list) {
			if (item.getAttribute("aria-label") === "Next") {
				item.click();
			}
		}

		if (availableOptions.length - 1 === likeindex) {
			setlastQuestion(true);
			localStorage.setItem("lastQuestion", true);
		}

		likeindex++;
		dataObj.options = val;
		copyData.question_json[index] = dataObj;
		setData(copyData);
		localStorage.setItem("open", true);
	};

	const handleClick = async () => {
		const newIndex = index + 1;
		setIndex(newIndex);

		// console.log(adobe);
		// targetView("PRODUCTS-PAGE-");

		setisPrevious(false);

		if (newIndex === data.question_json.length) {
			data.is_completed = 1;
			localStorage.removeItem("open");
			history.replace("/thankyou");
		}

		await Axios.post(config.user_response, data, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
	};

	const handlePreviousClick = (e) => {
		const newIndex = index - 1;
		setIndex(newIndex);

		// setOpenup(true);
		setisPrevious(true);
	};

	const handleReanswer = () => {
		setisPrevious(false);
		const copyData = { ...data };
		const dataObj = copyData.question_json[index];
		const availableOptions = dataObj.options;

		const val = availableOptions.map((elem) => {
			elem.checked = false;
			elem.likeChecked = false;
			elem.dislikeChecked = false;
			elem.score = 0;
			dataObj.count = 0;

			return elem;
		});

		dataObj.options = val;
		copyData.question_json[index] = dataObj;
		setData(copyData);
		localStorage.setItem("open", false);
		// setOpen(false);
		setlastQuestion(false);
	};

	function handleClose() {
		setOpenup(false);
		setisPrevious(false);

		localStorage.setItem("backClicked", false);
	}

	// function targetView(viewName) {
	// 	// Validate if the Target Libraries are available on your website
	// 	console.log(typeof adobe != "undefined", adobe);
	// 	if (
	// 		typeof adobe != "undefined" &&
	// 		adobe.target &&
	// 		typeof adobe.target.triggerView === "function"
	// 	) {
	// 		adobe.target.triggerView(viewName);
	// 	}
	// }

	return (
		<>
			{localStorage.getItem("token") ? (
				<>
					{Object.keys(data).length !== 0 ? (
						<>
							<QuestionsHeader
								length={
									Object.keys(data).length !== 0 ? data.question_json.length : 0
								}
								indexno={index}
							/>
							<main className={style.main + " container"} id="questions">
								<div className="row">
									<div className="col-sm-12">
										<div className="row align-items-center">
											{localStorage.getItem("backClicked") === "true" ? (
												<Dialog
													open={openup}
													aria-labelledby="responsive-dialog-title"
													className={style.modal}
												>
													<DialogContent>
														<DialogTitle className={style.modalHeader}>
															Are you sure want to leave the quiz?
														</DialogTitle>
													</DialogContent>
													<DialogActions>
														<Link to="/register">
															<Button autoFocus color="primary">
																Yes
															</Button>
														</Link>
														<Button
															onClick={handleClose}
															color="primary"
															autoFocus
														>
															No
														</Button>
													</DialogActions>
												</Dialog>
											) : (
												""
											)}
											{Object.keys(data).length !== 0 &&
												(data.question_json[index].type === 1 ? (
													<Checkbox
														index={index}
														onButtonClick={handleClick}
														questionData={data.question_json}
														onCheckBoxChange={handleCheckboxChange}
														checkboxData={data.question_json[index]}
														onButtonPreviousClick={handlePreviousClick}
														selectedCount={data.question_json[index].count}
													/>
												) : (
													<Radio
														open={open}
														data={data}
														index={index}
														openup={openup}
														clickRef={clickRef}
														isPrevious={isPrevious}
														onhandleClose={handleClose}
														lastQuestion={lastQuestion}
														onButtonClick={handleClick}
														onHandleReanswer={handleReanswer}
														onHandleLDChange={handleLDChange}
														questionData={data.question_json}
														radioData={data.question_json[index]}
														onRadioBtnChange={handleRadioBtnChange}
														onButtonPreviousClick={handlePreviousClick}
														selectedCount={data.question_json[index].count}
													/>
												))}
										</div>
									</div>
								</div>
							</main>
						</>
					) : (
						<CircularProgress color="secondary" className={style.loader} />
					)}
				</>
			) : (
				history.push("/quiz")
			)}
		</>
	);
}

export default Quiz;
