import { makeStyles } from "@material-ui/core/styles";

export const Styles = makeStyles((theme) => ({
	root: {},
	h1: {
		fontSize: 24,
		lineHeight: "33px",
		fontFamily: "RobotoSlab-Bold",
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px",
			lineHeight: "26px",
		},
	},
	p: {
		fontSize: 14,
		fontFamily: "NunitoSans-Regular",
		padding: "15px 0px",
		marginBottom: "5px",
		lineHeight: "25px",
		[theme.breakpoints.down("sm")]: {
			fontSize: 12,
			padding: "8px 0px",
			lineHeight: "18px",
		},
	},
	pointers: {
		display: "flex",
		alignItems: "baseline",
	},
	pointerFont: {
		fontSize: 13,
		fontFamily: "NunitoSans-Bold",
		padding: "13px 0px",
		borderBottom: "0.5px solid #fde7e7",
		[theme.breakpoints.down("sm")]: {
			fontSize: 11,
			padding: "5px 0px",
		},
	},
	points: {
		fontSize: 12,
		fontFamily: "NunitoSans-Bold",
		backgroundColor: "#e0787c",
		color: "#fff",
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		alignItems: "center",
		textAlign: "center",
		marginRight: "15px",
		display: "grid",
		[theme.breakpoints.down("xs")]: {
			fontSize: 11,
		},
	},
	button: {
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: "max-content",
		padding: "10px 15px",
		textTransform: "uppercase",
		margin: "40px 0px",
		fontSize: "15px",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "10px 0px",
			fontSize: 12,
			textAlign: "center",
			width: "100%",
		},
	},
	main: {
		[theme.breakpoints.down("sm")]: {
			margin: "auto",
			overflow: "hidden",
			marginTop: "2rem",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "0px",
			overflow: "hidden",
		},
	},
	wrapper: {
		backgroundColor: "#fff",
		borderRadius: "20px",
	},
	sliderPadding: {
		paddingLeft: "20px",
		[theme.breakpoints.down("sm")]: {
			order: 2,
		},
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "0px",
			order: 1,
		},
	},
	header: {
		textAlign: "center",
		padding: "1rem",
		[theme.breakpoints.down("sm")]: {
			padding: "20px 0px",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "0.5rem",
		},
	},
	logoWidth: {
		[theme.breakpoints.down("xs")]: {
			width: "121px",
		},
	},
	// Content page
	content: {
		padding: "3rem 3rem 4rem 4rem",
		paddingBottom: "0px",
		[theme.breakpoints.down("sm")]: {
			padding: "1rem",
			marginTop: "0rem",
			backgroundColor: "#fff",
			borderRadius: "15px",
			zIndex: "999",
			paddingLeft: 30,
		},
		[theme.breakpoints.down("xs")]: {
			padding: "1rem",
			marginTop: "-1rem",
			backgroundColor: "#fff",
			borderRadius: "15px",
			zIndex: "999",
		},
	},
	setOrder: {
		[theme.breakpoints.down("sm")]: {
			order: 1,
			zIndex: 10,
			marginTop: "0rem",
		},
		[theme.breakpoints.down("xs")]: {
			order: 2,
			zIndex: 10,
			// marginTop: "-2rem",
		},
	},
	colSm: {
		[theme.breakpoints.down("xs")]: {
			padding: 0,
		},
	},
}));
