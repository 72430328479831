import React from "react";
import TextRadioButton from "./TextRadioButton";
import ImageRadioButton from "./ImageRadioButton";

function RadioButton(props) {
	const { radioData } = props;
	return (
		<>
			{radioData.format === 1 ? (
				<ImageRadioButton {...props} />
			) : (
				<TextRadioButton {...props} />
			)}
		</>
	);
}

export default RadioButton;
