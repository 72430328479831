import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
	imageWidth: {
		width: "100%",
		borderRadius: "15px 15px 0px 0px",
	},
	h4: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "24px",
		[theme.breakpoints.down("md")]: {
			width: "400px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 18,
			width: "auto",
		},
	},
	p: {
		fontFamily: "NunitoSans-Regular",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			width: "400px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 12,
			width: "auto",
		},
	},
	row: {
		marginTop: "10px",
	},
	span: {
		background: "#ffe9ea",
		display: "inline-block",
		textTransform: "lowercase",
	},
	h1: {
		fontFamily: "RobotoSlab-Bold",
		fontSize: "32px",
		margin: "2rem 0rem",
		marginLeft: "20px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
		},
	},
	h2: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "24px",
		margin: "2rem 0rem",
		marginRight: "auto",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
			textAlign: "center",
			margin: "auto",
			marginBottom: 20,
			marginTop: 20,
		},
	},
	styleTip: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "#fff",
		borderRadius: "15px",
		marginTop: "-2rem",
		[theme.breakpoints.down("md")]: {
			display: "inline-flex",
			width: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			display: "inline-flex",
			width: "100%",
		},
	},
	tipImage: {},
	styleTipDesc: {
		fontSize: "13px",
		width: "280px",
		padding: "5px 10px",
		marginLeft: "10px",
		marginBottom: "5px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 11,
			width: "auto",
		},
	},
	title: {
		display: "flex",
		alignItems: "center",
	},
	retakeSection: {
		justifyContent: "flex-end",
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
			marginTop: 20,
		},
	},
	styleTitle: {
		fontSize: "14px",
		textTransform: "capitalize",
		fontFamily: "NunitoSans-Bold",
	},
	styleDescription: {
		fontSize: "16px",
		fontFamily: "NunitoSans-Regular",
		[theme.breakpoints.down("xs")]: {
			fontSize: 14,
		},
	},
	subStyleHeading: {
		fontSize: "16px",
		fontFamily: "NunitoSans-Bold",
		marginBottom: "10px",
	},
	subHeading: {
		fontSize: "16px",
		fontFamily: "NunitoSans-Bold",
		marginBottom: "30px",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
		},
	},
	borderLine: {
		paddingTop: "30px",
		borderTop: "1px solid #e6e2e2",
		[theme.breakpoints.down("xs")]: {
			borderTop: "none",
		},
	},
	i: {
		fontSize: "18px",
		color: "#ed1c24",
		marginRight: "35px",
	},
	icons: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	retake: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "12px",
		color: "#ed1c24",
		marginRight: "10px",
	},
	generateReport: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "12px",
		color: "#ed1c24",
		marginRight: "10px",
		marginLeft: 20,
	},
	retakeQuiz: {
		pointerEvents: "auto",
		cursor: "pointer",
	},
	highDescription: {
		position: "absolute",
		top: "25%",
		width: "350px",
		right: "2%",
		fontSize: "18px",
		[theme.breakpoints.down("md")]: {
			position: "initial",
			margin: "auto",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			position: "initial",
			fontSize: 14,
			textAlign: "left",
			marginTop: 20,
		},
	},
	/************ Get started heading **************/
	headingsGetStarted: {
		position: "absolute",
		textAlign: "center",
		top: "40%",
		[theme.breakpoints.down("xs")]: {
			top: "45%",
		},
	},
	headingh2: {
		fontSize: 34,
		fontFamily: "RobotoSlab-Bold",
		color: "#fff",
		width: "600px",
		lineHeight: "52px",
		letterSpacing: "0.7px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 17,
			lineHeight: "25px",
			width: "300px",
		},
	},
	hyperlink: {
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: "200px",
		padding: "10px 15px",
		textTransform: "uppercase",
		fontSize: "15px",
		margin: "20px",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			width: "auto",
			margin: "10px",
		},
	},
	btns: {
		display: "flex",
		justifyContent: "center",
	},
	grid: {
		display: "grid",
		[theme.breakpoints.down("md")]: {
			display: "block",
			marginLeft: "-4rem",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
			marginLeft: "0rem",
			marginTop: "1rem",
		},
	},
	alignCenter: {
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			width: 60,
			marginLeft: 8,
		},
		[theme.breakpoints.down("xs")]: {
			width: 95,
			marginLeft: 8,
		},
	},
	getStarted: {
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {},
	},
	desktop: {
		display: "block",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	mobile: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	modal: {
		"& .MuiButtonBase-root": {
			outline: "none",
			[theme.breakpoints.down("xs")]: {
				marginLeft: -15,
			},
		},
		"& .MuiButton-label": {
			outline: "none",
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 11,
			},
		},
		"& .MuiDialogTitle-root": {
			paddingLeft: "0px",
		},
		"& .MuiTypography-root": {
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 13,
			},
		},
		"& .MuiDialogContentText-root": {
			color: "#818181",
			fontFamily: "NunitoSans-Regular",
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
		"& .MuiDialog-paper": {
			borderBottom: "2px solid #ed1c24",
		},
	},
}));
