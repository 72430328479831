import React from "react";
import RadioButton from "./RadioButton";
import LikeDislikeButton from "./LikeDislikeButton";

function Radio(props) {
	const { radioData } = props;
	return (
		<>
			{radioData.type === 0 ? (
				<RadioButton {...props} />
			) : (
				<LikeDislikeButton {...props} />
			)}
		</>
	);
}

export default Radio;
