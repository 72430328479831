import React, { useEffect } from "react";
import { Styles } from "../../styles/HomepageStyles";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Content from "./Content";
import Slider from "./Slider";

function Homepage() {
	useEffect(() => {
		document.body.style.backgroundColor = "#f8f4f4";
	});

	const style = Styles();
	return (
		<>
			{/* Code by Pushpanjali */}
			<Header />
			<main className={style.main + " container"} id="Homepage">
				<div className="row">
					<div className={style.colSm + " col-sm-12"}>
						<div className={style.wrapper + " wrapper"}>
							<div className="row" style={{ alignItems: "center" }}>
								<div className={style.setOrder + " col-sm-6"}>
									<Content />
								</div>
								<div className={style.sliderPadding + " col-sm-6"}>
									<Slider />
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Homepage;
