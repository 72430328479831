import React from "react";
import ImageSlider from "./imageSlider";
import MixedSlider from "./mixedSlider";
import { Paper } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { Styles } from "../../styles/CarouselStyling";

function CarouselTemplate(props) {
	const { item: items, type, autoPlay } = props;
	const styles = Styles();

	return (
		<Carousel
			animation="slide"
			timeout={500}
			interval={4000}
			autoPlay={autoPlay}
			className={styles.root}
			activeIndicatorProps={{ style: { color: "#ed1c24" } }}
			navButtonsAlwaysInvisible={true}
			indicatorContainerProps={{
				style: {
					position: "absolute",
					bottom: 20,
					"@media (minWidth: 767px)": {
						bottom: 60,
					},
				},
			}}
		>
			{items.map((item) => (
				<Item key={item} item={item} type={type} />
			))}
		</Carousel>
	);
}

function Item(props) {
	const { type } = props;
	return (
		<Paper>
			{type === "image" ? (
				<ImageSlider item={props.item} />
			) : (
				<MixedSlider items={props.item} />
			)}
		</Paper>
	);
}

export default CarouselTemplate;
