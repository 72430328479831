import React from "react";
import { Radar } from "react-chartjs-2";

function Chart(props) {
	const { barOptions, barData, chartRef } = props;
	// return JSX
	return (
		<div className="BarExample">
			<Radar data={barData} options={barOptions} ref={chartRef} />
		</div>
	);
}

export default Chart;
