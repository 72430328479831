import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "578px",
		[theme.breakpoints.down("xs")]: {
			// height: "300px",
			height: "185px",
		},
		// [theme.breakpoints.down("xs")]: {
		// 	// height: "300px",
		// 	height: "210px",
		// },
		"@media (min-width: 375px)": {
			height: "250px",
		},
		"@media (min-width: 414px)": {
			height: "300px",
		},
		"@media (min-width: 1000px)": {
			height: "578px",
		},
	},
	desktop: {
		display: "block",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	mobile: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
}));

function imageSlider(props) {
	const styles = style(props);
	return (
		<>
			<img
				src={props.item.imageUrl}
				alt="Pick Your Style"
				className={`${styles.root} ${styles.desktop}`}
			/>
			<img
				src={props.item.mobileUrl}
				alt="Pick Your Style"
				className={`${styles.root} ${styles.mobile}`}
			/>
		</>
	);
}

export default imageSlider;
