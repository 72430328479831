import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
	root: {
		display: "none",
	},
	carouselLD: {
		width: "72%",
		[theme.breakpoints.down("xs")]: {
			width: "90%",
		},
		"& .MuiButtonBase-root": {
			background: "transparent",
			color: "#ed1c24",
			padding: 0,
			margin: 0,
		},
	},
	h1: {
		fontSize: 34,
		fontFamily: "RobotoSlab-Bold",
		letterSpacing: "0.35px",
		display: "inline-block",
		lineHeight: "1.45em",
		marginTop: "30px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
			textAlign: "center",
			marginTop: "15px",
			display: "block",
		},
	},
	p: {
		fontFamily: "NunitoSans-Regular",
		fontSize: 16,
		marginTop: 20,
		[theme.breakpoints.down("xs")]: {
			fontSize: 12,
			marginTop: 10,
			textAlign: "center",
		},
	},
	button: {
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: 125,
		padding: "10px 15px",
		textTransform: "uppercase",
		margin: "40px 0px",
		fontSize: "15px",
		border: "none",
		outline: "none",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
			backgroundColor: "#ed1c24",
		},
		"&:focus": {
			outline: "none",
		},
		"&:disabled": {
			backgroundColor: "rgb(228 0 0 / 16%)",
			cursor: "not-allowed",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			fontSize: 12,
			position: "sticky",
			bottom: 0,
			margin: 0,
			height: 40,
			marginBottom: 20,
			marginTop: 20,
		},
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	previousBtn: {
		marginRight: 20,
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: 125,
		padding: "10px 15px",
		textTransform: "uppercase",
		margin: "40px 0px",
		fontSize: "15px",
		border: "none",
		outline: "none",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
			backgroundColor: "#ed1c24",
		},
		"&:disabled": {
			backgroundColor: "rgb(228 0 0 / 16%)",
		},
		"&:focus": {
			outline: "none",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			fontSize: 12,
			position: "sticky",
			bottom: 0,
			margin: 0,
			height: 40,
			marginBottom: 20,
			marginRight: 20,
			marginTop: 20,
		},
	},
	mansonry: {
		overflowY: "scroll",
		height: "700px",
		overflowX: "hidden",
		paddingLeft: "40px",
		[theme.breakpoints.down("xs")]: {
			height: "auto",
			paddingLeft: "15px",
		},
	},
	checkboxNone: {
		display: "none",
	},
	span: {
		fontSize: 10,
		borderRadius: 20,
		padding: "6px 15px",
		backgroundColor: "#fff",
		fontFamily: "NunitoSans-Bold",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
			display: "table",
			margin: "auto",
		},
	},
	questionCount: {
		marginLeft: 5,
		color: "#ed1c24",
	},
	backdropShow: {
		background: "rgba(0,0,0,0.46)",
		position: "absolute",
		borderRadius: 5,
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		opacity: 1,
		transition: "all 0.2s ease-in-out 0s",
	},
	backdrop: {
		background: "rgba(0,0,0,0.5)",
		position: "absolute",
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		opacity: 0,
		transition: "all 0.2s ease-in-out 0s",
	},
	checkedImage: {
		float: "right",
		margin: "20px",
		borderRadius: 5,
		[theme.breakpoints.down("xs")]: {
			width: 23,
			height: 23,
			margin: 13,
		},
	},
	textButton: {
		width: "330px",
		height: "180px",
		display: "table-cell",
		alignItems: "center",
		textAlign: "center",
		margin: "auto",
		verticalAlign: "middle",
		boxShadow: "2px 2px 5px 0 rgba(45, 43, 43, 0.12)",
		background: "#fff",
	},
	textButtonAfterSelect: {
		width: "330px",
		height: "180px",
		display: "table-cell",
		alignItems: "center",
		textAlign: "center",
		margin: "auto",
		verticalAlign: "middle",
		boxShadow: "2px 2px 5px 0 rgba(45, 43, 43, 0.12)",
		background: "#fde9ea",
	},
	h4: {
		margin: "auto",
		width: "150px",
		marginTop: "20px",
		fontFamily: "NunitoSans-Bold",
		fontSize: 16,
	},
	radioTextLabel: {
		margin: "1rem",
	},
	// Like dislike
	swipe: {
		position: "absolute",
	},
	cardContainer: {
		maxWidth: "100vw",
		height: "700px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& .MuiPaper-root": {
			backgroundColor: "#fff",
			transition: "none",
		},
		"& .MuiPaper-elevation1": {
			boxShadow: "none",
		},
		"& .MuiCardActions-root": {
			justifyContent: "center",
		},
		"& .MuiTouchRipple-root:focus": {
			outline: "none",
		},
		"& button:focus": {
			outline: "none",
		},
		"& button:hover": {
			backgroundColor: "#fff",
		},
		"& .MuiCardContent-root": {
			padding: 0,
		},
		[theme.breakpoints.down("xs")]: {
			height: "auto",
		},
	},
	modal: {
		"& .MuiButtonBase-root": {
			outline: "none",
			[theme.breakpoints.down("xs")]: {
				marginLeft: -15,
			},
		},
		"& .MuiButton-label": {
			outline: "none",
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 11,
			},
		},
		"& .MuiDialogTitle-root": {
			paddingLeft: "0px",
		},
		"& .MuiTypography-root": {
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 14,
			},
		},
		"& .MuiDialogContentText-root": {
			color: "#818181",
			fontFamily: "NunitoSans-Regular",
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
		"& .MuiDialog-paper": {
			borderBottom: "2px solid #ed1c24",
		},
	},
	card: {
		height: "450px",
		background: "#fff",
		borderRadius: "5px",
		display: "table-cell",
		verticalAlign: "middle",
		boxShadow: "-2px 2px 10px 0 rgba(45, 43, 43, 0.05)",
	},
	"@keyframes bounce": {
		"0%, 20%, 50%, 80%, 100%": {
			transform: "translateY(0)",
		},
		"40%": {
			transform: "translateY(-10px)",
		},
		"60%": {
			transform: "translateY(-5px)",
		},
	},
	scroll: {
		position: "fixed",
		bottom: "10%",
		right: "27%",
		left: 0,
		color: "#ed1c24",
		fontFamily: "NunitoSans-Bold",
		fontSize: 15,
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	scrollArrow: {
		animation: "$bounce 5s infinite",
		fontSize: 20,
	},
	likeDislikeImage: {
		width: 346,
		height: 360,
		background: "#fff",
		borderRadius: 10,
		backgroundRepeat: "no-repeat",
		margin: "auto",
		backgroundSize: "contain",
		"&:focus": {
			outline: "none",
		},
		[theme.breakpoints.down("xs")]: {
			width: 240,
			height: 268,
			backgroundSize: "100% 100%",
		},
	},
	likeDislikeLabel: {
		width: 60,
		height: 60,
		margin: "20px",
		[theme.breakpoints.down("xs")]: {
			width: 41,
			height: 41,
		},
	},
	rotateLeft: {
		transform: "translate(-1696.76px, -67.9278px) rotate(62.7214deg);",
		transition: "all 2.5s ease 0s !important",
		marginLeft: "1000px",
		cursor: "e-resize",
		opacity: 0,
		zIndex: 10,
	},
	rotateRight: {
		transform: "translate(1696.07px, 83.2957px) rotate(-57.3555deg);",
		transition: "all 2.5s ease 0s !important",
		marginLeft: "-1000px",
		cursor: "w-resize",
		opacity: 0,
		zIndex: 10,
	},
	backLeft: {
		transform: "translate(0px) rotate(0);",
		transition: "all 2.5s ease 0s !important",
		marginLeft: "0px",
		cursor: "e-resize",
		opacity: 1,
		zIndex: 10,
	},
	backRight: {
		transform: "translate(0px) rotate(0)",
		transition: "all 2.5s ease 0s !important",
		marginLeft: "0px",
		cursor: "w-resize",
		opacity: 1,
		zIndex: 10,
	},
	disable: {
		pointerEvents: "none",
	},
	paper: {
		position: "absolute",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		top: "37%",
		left: "37%",
	},
	focusHighlight: {
		background: "#fff",
	},
	desktop: {
		display: "block",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	mobile: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	containerCarousel: {
		position: "relative",
		height: "100vh",
		width: "100%",
		display: "flex",
		flex: 1,
		justifyContent: "center",
		alignItems: "middle",
	},
}));
