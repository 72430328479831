import Axios from "axios";
import Blogs from "./Blogs/Blogs";
import Products from "./Products/Products";
import GetStarted from "./Blogs/GetStarted";
import LowReportData from "./LowReportData";
import GenerateReport from "./GenerateReport";
import HighReportData from "./HighReportData";
import config from "../../services/config.json";
import { makeStyles } from "@material-ui/core/styles";
import QuestionsFooter from "../../common/QuestionsFooter";
import QuestionsHeader from "../../common/QuestionsHeader";
import React, { useEffect, useState, createRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Styles = makeStyles(() => ({
	main: {
		marginTop: "1rem",
		overflow: "hidden",
	},
	loader: {
		position: "absolute",
		top: "44%",
		left: "50%",
	},
}));

function UserReport(props) {
	const style = Styles();
	const chartRef = createRef();
	const ref = React.createRef();
	const [blog, setBlog] = useState([]);
	const [product, setProduct] = useState([]);
	const [report, setReport] = useState([]);
	const [chartImage, setChartImage] = useState("");

	function delay(t) {
		return new Promise((resolve) => setTimeout(resolve, t));
	}

	useEffect(() => {
		// Code By Pushpanjali
		async function getData() {
			await delay(1000);
			const response = await Axios.get(config.user_report, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			});
			let user_report_json = null;
			if (response.data.data.user_result !== null) {
				user_report_json = response.data.data.user_result;
			}
			const sorted = user_report_json.sort((a, b) => {
				return b.value - a.value;
			});
			setReport(sorted);

			const blogs = response.data.data.blogs;
			setBlog(blogs);

			const products = response.data.data.products;
			setProduct(products);
		}
		document.body.style.backgroundColor = "#f8f4f4";
		getData();
	}, []);

	const barData = {
		labels: report.map((x) => x.style),
		datasets: [
			{
				label: "",
				data: report.map((x) => Math.round(x.value)),
				backgroundColor: [
					"rgba(237, 28, 36, 0.5)",
					// "rgba(54, 162, 235, 0.6)",
					// "rgba(255, 206, 86, 0.6)",
				],
				borderWidth: 3,
				lineTension: 0,
			},
		],
	};
	// set options
	const barOptions = {
		responsive: true,
		aspectRatio: 1,
		maintainAspectRatio: true,
		scale: {
			pointLabels: {
				fontStyle: "bold",
			},
			ticks: {
				beginAtZero: true,
				display: false,
				// max: 100,
			},
		},
		legend: {
			display: false,
		},
		animation: {
			onComplete: async function () {
				const imageUrl = chartRef.current.chartInstance.toBase64Image();
				setChartImage(imageUrl);
				await Axios.post(
					config.send_graph,
					{ image: imageUrl },
					{
						headers: {
							Authorization: "Bearer " + localStorage.getItem("token"),
						},
					}
				);
			},
		},
		tooltips: {
			enable: true,
			callbacks: {
				label: function (tooltipItem, data) {
					var label = data.labels[tooltipItem.index];
					var val =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return label + " : " + Math.round(val) + "%";
				},
			},
		},
	};

	return (
		<>
			{report.length !== 0 ? (
				<>
					<QuestionsHeader length="0" indexno="0" />
					<main
						className={style.main + " container"}
						id="user-report"
						ref={ref}
					>
						{report.length !== 0 ? (
							<HighReportData
								barData={barData}
								chartRef={chartRef}
								reportData={report}
								barOptions={barOptions}
							/>
						) : (
							""
						)}
						{report.length !== 0 ? <LowReportData reportData={report} /> : ""}
						{report.length !== 0 && chartImage !== "" ? (
							<GenerateReport reportData={report} chartImage={chartImage} />
						) : (
							""
						)}
					</main>
					<GetStarted />
					{blog.length !== 0 ? <Blogs blogs={blog} /> : ""}
					{product.length !== 0 ? <Products products={product} /> : ""}
					<QuestionsFooter />
				</>
			) : (
				<CircularProgress color="secondary" className={style.loader} />
			)}
		</>
	);
}

export default UserReport;
