import React from "react";
import { Link } from "react-router-dom";
import MyDocument from "./PDF/MyDocument";
import retake from "../../images/redo.svg";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";
import download from "../../images/download.svg";
import { Styles } from "../../styles/ReportStyles";
import {
	FacebookShareButton,
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,
} from "react-share";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

function GenerateReport(props) {
	const style = Styles();
	const { chartImage, reportData } = props;
	const siteUrl = document.location.origin;
	// const siteUrl = "https://quiz.beautifulhomes.com/";
	const text = "Up your decor game! Take the home decor quiz - ";

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div
				className={style.borderLine + " row"}
				style={{
					paddingBottom: "30px",
					borderBottom: "1px solid #e6e2e2",
				}}
			>
				<div className="col-sm-12">
					<div className="row" style={{ alignItems: "center" }}>
						<div className="col-sm-6">
							<div className="social-icons">
								<h6 className={style.subHeading}>Share Your Decor Style</h6>
								<div className={style.icons}>
									<WhatsappShareButton
										url={siteUrl}
										title={text}
										quote="Show me the Google!"
										// separator=" - "
										media={whatsapp}
										style={{ outline: "none" }}
									>
										<img src={whatsapp} className={style.i} alt="Whatsapp" />
									</WhatsappShareButton>
									<FacebookShareButton
										url={siteUrl}
										quote={text}
										style={{ outline: "none" }}
									>
										<i
											className={style.i + " fa fa-facebook"}
											aria-hidden="true"
										></i>
									</FacebookShareButton>
									<TwitterShareButton
										url={siteUrl}
										title={text}
										style={{ outline: "none" }}
									>
										<i
											className={style.i + " fa fa-twitter"}
											aria-hidden="true"
										></i>
									</TwitterShareButton>
									<TelegramShareButton
										url={siteUrl}
										title={text}
										style={{ outline: "none" }}
									>
										<img src={telegram} alt="Telegram" />
									</TelegramShareButton>
								</div>
							</div>
						</div>
						<div className={style.retakeSection + " col-sm-6"}>
							{/* <Link to="/register"> */}
							<div className={style.retakeQuiz}>
								<span className={style.retake} onClick={handleClickOpen}>
									Retake Quiz
								</span>
								<img src={retake} alt="Retake" />
							</div>
							{/* </Link> */}
							<PDFDownloadLink
								document={
									<MyDocument chartImage={chartImage} reportData={reportData} />
								}
								fileName="Report.pdf"
							>
								{({ loading }) =>
									loading ? (
										<span className={style.generateReport}>
											Generating Report...
										</span>
									) : (
										<div style={{ marginLeft: 20 }}>
											<span className={style.retake}>Download Report</span>
											<img src={download} alt="Retake" />
										</div>
									)
								}
							</PDFDownloadLink>
							{/* <button onClick={() => setPdf(true)}>Download</button> */}
						</div>
					</div>
				</div>
			</div>
			<Dialog
				open={open}
				aria-labelledby="responsive-dialog-title"
				className={style.modal}
			>
				<DialogContent>
					<DialogTitle className={style.modalHeader}>
						Are you sure want to retake the quiz?
					</DialogTitle>
				</DialogContent>
				<DialogActions>
					<Link to="/register">
						<Button autoFocus color="primary">
							Yes
						</Button>
					</Link>
					<Button onClick={handleClose} color="primary" autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
			{/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
				<MyDocument chartImage={chartImage} reportData={reportData} />
			</PDFViewer> */}
		</>
	);
}

export default GenerateReport;
