import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
	root: {
		display: "none",
	},
	"& .row .MuiFormControlLabel-root": {
		marginLeft: "0px",
		marginRight: "0px",
	},
	h1: {
		fontSize: 34,
		fontFamily: "RobotoSlab-Bold",
		letterSpacing: "0.35px",
		display: "inline-block",
		lineHeight: "1.45em",
		marginTop: "30px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
			textAlign: "center",
			marginTop: "15px",
		},
	},
	p: {
		fontFamily: "NunitoSans-Regular",
		fontSize: 16,
		marginTop: 20,
		[theme.breakpoints.down("xs")]: {
			fontSize: 12,
			marginTop: 10,
			textAlign: "center",
		},
	},
	button: {
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: 125,
		padding: "10px 15px",
		textTransform: "uppercase",
		margin: "40px 0px",
		fontSize: "15px",
		border: "none",
		outline: "none",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
			backgroundColor: "#ed1c24",
		},
		"&:focus": {
			outline: "none",
		},
		"&:disabled": {
			backgroundColor: "rgb(228 0 0 / 16%)",
			cursor: "not-allowed",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			fontSize: 12,
			position: "sticky",
			bottom: 0,
			margin: 0,
			height: 40,
			marginBottom: 20,
			marginTop: 20,
		},
	},
	flex: {
		display: "flex",
	},
	previousBtn: {
		marginRight: 20,
		backgroundColor: "#ed1c24",
		color: "#fff",
		display: "block",
		width: 125,
		padding: "10px 15px",
		textTransform: "uppercase",
		margin: "40px 0px",
		fontSize: "15px",
		border: "none",
		outline: "none",
		"&:hover": {
			color: "#fff",
			textDecoration: "none",
			backgroundColor: "#ed1c24",
		},
		"&:disabled": {
			backgroundColor: "rgb(228 0 0 / 16%)",
		},
		"&:focus": {
			outline: "none",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			fontSize: 12,
			position: "sticky",
			bottom: 0,
			margin: 0,
			height: 40,
			marginBottom: 20,
			marginRight: 20,
			marginTop: 20,
		},
	},
	span: {
		fontSize: 10,
		borderRadius: 20,
		padding: "6px 15px",
		backgroundColor: "#fff",
		fontFamily: "NunitoSans-Bold",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
			display: "table",
			margin: "auto",
		},
	},
	questionCount: {
		marginLeft: 5,
		color: "#ed1c24",
	},
	textButton: {
		width: "330px",
		height: "180px",
		display: "table-cell",
		alignItems: "center",
		textAlign: "center",
		margin: "auto",
		verticalAlign: "middle",
		boxShadow: "2px 2px 5px 0 rgba(45, 43, 43, 0.12)",
		background: "#fff",
	},
	textButtonAfterSelect: {
		width: "330px",
		height: "180px",
		display: "table-cell",
		alignItems: "center",
		textAlign: "center",
		margin: "auto",
		verticalAlign: "middle",
		boxShadow: "2px 2px 5px 0 rgba(45, 43, 43, 0.12)",
		background: "#fde9ea",
	},
	h4: {
		margin: "auto",
		width: "150px",
		marginTop: "20px",
		fontFamily: "NunitoSans-Bold",
		fontSize: 16,
		[theme.breakpoints.down("xs")]: {
			width: 200,
			fontSize: 13,
		},
	},
	radioTextLabel: {
		margin: "1rem",
		marginLeft: "1rem!important",
		marginRight: "1rem!important",
		[theme.breakpoints.down("xs")]: {
			margin: "0.5rem",
		},
	},
	imageSelected: {
		position: "absolute",
		top: "20px",
		right: "20px",
		[theme.breakpoints.down("xs")]: {
			width: 23,
			height: 23,
			margin: 13,
		},
	},
	text3d: {
		textTransform: "uppercase",
		fontFamily: "SanMarinoBeach",
		fontSize: "30px",
		color: "#ed1c24",
	},
	desktop: {
		display: "block",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	mobile: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
}));
