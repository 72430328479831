import { createStyles } from "@material-ui/core";

export const Styles = (theme) =>
	createStyles({
		h1: {
			fontSize: 34,
			fontFamily: "RobotoSlab-Bold",
			[theme.breakpoints.down("xs")]: {
				fontSize: 20,
				textAlign: "center",
			},
		},
		p: {
			fontSize: 16,
			fontFamily: "NunitoSans-Regular",
			marginTop: 15,
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
				textAlign: "center",
			},
		},
		button: {
			backgroundColor: "#ed1c24",
			color: "#fff",
			display: "block",
			width: 125,
			padding: "10px 15px",
			textTransform: "uppercase",
			margin: "40px 0px",
			fontSize: "15px",
			borderRadius: 3,
			marginBottom: 30,
			"&:hover": {
				color: "#fff",
				textDecoration: "none",
				backgroundColor: "#ed1c24",
			},
			"&:disabled": {
				backgroundColor: "rgb(228 0 0 / 16%)",
			},
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				margin: "20px 0px",
				fontSize: 12,
				// position: "sticky",
				// bottom: 0,
			},
		},
		desktop: {
			display: "block",
			[theme.breakpoints.down("xs")]: {
				display: "none",
			},
		},
		mobile: {
			display: "none",
			[theme.breakpoints.down("xs")]: {
				display: "block",
			},
		},
		form: {
			display: "grid",
			gridGap: "10px",
			width: "80%",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				margin: "auto",
			},
		},
		row: {
			alignItems: "center",
			"& .Mui-disabled": {
				pointerEvents: "auto",
				cursor: "not-allowed",
			},
			"& .MuiButton-root:hover.Mui-disabled": {
				backgroundColor: "rgb(228 0 0 / 16%)",
				color: "rgba(0, 0, 0, 0.26)",
			},
		},
		label: {
			textTransform: "uppercase",
		},
		span: {
			backgroundColor: "#e4000029",
		},
		alertMessage: {
			color: "#ed1c24",
			fontFamily: "NunitoSans-Bold",
		},
	});
