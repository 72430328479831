import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		fontSize: "10px",
		fontFamily: "NunitoSans-Regular",
	},
	i: {
		fontSize: "10px",
		marginRight: "5px",
	},
	footer: {
		textAlign: "center",
		marginBottom: "20px",
	},
});

function QuestionsFooter(props) {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<h6>
				<i className={classes.i + " fa fa-copyright"} aria-hidden="true"></i>
				<span className={classes.root}>
					Beautiful Homes 2019 | All rights reserved
				</span>
			</h6>
		</footer>
	);
}

export default QuestionsFooter;
