import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: "#ed1c24",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#ed1c24",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ed1c24",
				borderWidth: "2px",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#ed1c24",
			},
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#ed1c24",
			[theme.breakpoints.down("xs")]: {
				fontSize: 16,
			},
		},
		"& .MuiInputLabel-outlined": {
			// fontSize: 14,
			fontFamily: "NunitoSans-Bold",
			// lineHeight: "30px",
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
		"& .MuiOutlinedInput-input": {
			fontSize: 16,
			fontFamily: "NunitoSans-Regular",
			color: "#000",
			// height: 30,
		},
		"& .MuiFormHelperText-contained": {
			color: "red",
		},
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0,
		},
	},
}));

function MakingInput(props) {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		decrypted,
	} = props;
	const style = useStyle();

	return (
		<>
			{/* <InputMask
				// mask="(+\91) 99999 99999"
				mask="99999 99999"
				disabled={false}
				maskChar=" "
				value={values.mobile}
				onChange={handleChange}
				onBlur={handleBlur}
			>
				{() => ( */}
			<TextField
				id="mobile"
				label="MOBILE NUMBER *"
				helperText={
					touched.mobile || (errors.mobile && decrypted) || values.mobile
						? errors.mobile
						: ""
				}
				error={touched.mobile && Boolean(errors.mobile)}
				margin="dense"
				variant="outlined"
				fullWidth
				className={style.root}
				inputProps={{ inputMode: "numeric" }}
				value={values.mobile}
				onChange={handleChange}
				onBlur={handleBlur}
				type="number"
				onInput={(e) => {
					e.target.value = Math.max(0, parseInt(e.target.value))
						.toString()
						.slice(0, 10);
				}}
			/>
			{/* )}
			</InputMask> */}
			<InputMask
				mask="999 999"
				disabled={false}
				maskChar=" "
				value={values.pincode}
				onChange={handleChange}
				onBlur={handleBlur}
			>
				{() => (
					<TextField
						id="pincode"
						label="PINCODE *"
						helperText={
							touched.pincode || (errors.pincode && decrypted)
								? errors.pincode
								: ""
						}
						error={touched.pincode && Boolean(errors.pincode)}
						margin="dense"
						variant="outlined"
						fullWidth
						className={style.root}
						inputProps={{ inputMode: "numeric" }}
					/>
				)}
			</InputMask>
		</>
	);
}

export default MakingInput;
