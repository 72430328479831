import * as yup from "yup";

const validationsForm = {
	name: yup
		.string()
		.strict(true)
		.matches(/^[A-Za-z]/, "Name must not have numbers")
		.required("Name is Required"),
	email: yup
		.string()
		.email("Enter a valid email")
		.required("Email is required"),
	mobile: yup
		.string()
		.required("Mobile No. is required")
		.test("len", "Invalid Mobile No.", (val = "") => {
			let val_length_without_dashes = val
				.replace("(+91)", "")
				.replace(/ /g, "");
			if (val_length_without_dashes.length > 10) {
				val_length_without_dashes = val_length_without_dashes.substr(2);
			}
			return val_length_without_dashes.length === 10;
		}),
	pincode: yup
		.string()
		.required("Pincode is required")
		.test("len", "Pincode must contain 6 digits", (val = "") => {
			const exactValue = val.replace(/ /g, "");
			return exactValue.length === 6;
		}),
};

export default validationsForm;
