import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Styles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	bottom: {
		color: "#e3e3e3",
	},
	top: {
		color: "#ed1c24",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
	circle: {
		strokeLinecap: "round",
	},
	completed: {
		fontSize: "10px",
		fontFamily: "NunitoSans-Bold",
		marginTop: "-6px",
		[theme.breakpoints.down("xs")]: {
			fontSize: 8,
		},
	},
	typo: {
		fontSize: "10px",
		fontFamily: "NunitoSans-Bold",
		color: "#000",
		[theme.breakpoints.down("xs")]: {
			fontSize: 8,
		},
	},
}));

function ProgressBar(props) {
	const classes = Styles();
	const { value } = props;
	return (
		<>
			<div className={classes.root}>
				<Box position="relative" display="inline-flex">
					<CircularProgress
						variant="determinate"
						className={classes.bottom}
						size={40}
						thickness={4}
						{...props}
						value={100}
					/>
					<CircularProgress
						variant="determinate"
						value={value}
						className={classes.top}
						classes={{
							circle: classes.circle,
						}}
						size={40}
						thickness={4}
						{...props}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography
							variant="caption"
							component="div"
							color="textSecondary"
							className={classes.typo}
						>{`${Math.round(props.value)}%`}</Typography>
					</Box>
				</Box>
				<div className={classes.completed}>Completed</div>
			</div>
		</>
	);
}

export default ProgressBar;
