import Axios from "axios";
import * as yup from "yup";
import { withFormik } from "formik";
import TextInput from "./TextInput";
import queryString from "query-string";
import MaskingInput from "./MaskingInput";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import config from "../../services/config.json";
import React, { useEffect, useState } from "react";
import Questions from "../renderTemplate/Questions";
import { Styles } from "../../styles/QuestionsStyle";
import validationsForm from "../../template/Form/Validation";
// import createTargetComponent from "@adobe/target-react-component";

var CryptoJS = require("crypto-js");
let decryptedString = "";
// const Target = createTargetComponent(React);

const form = (props) => {
	const { handleSubmit, isValid } = props;
	localStorage.setItem("backClicked", false);

	return (
		<div className="col-sm-12">
			<div className={props.classes.row + " row"}>
				<div className="col-sm-6">
					<h1 className={props.classes.h1}>
						Tell us a bit{" "}
						<span className={props.classes.span}> about yourself</span>
					</h1>
					<p className={props.classes.p}>
						Fill in your details. Let’s get to know each other.
					</p>
					<Button
						type="submit"
						className={`${props.classes.button} ${props.classes.desktop}`}
						form="register-form"
						disabled={!isValid}
					>
						NEXT
						{/* <Target data-mbox="Register-Button-click">NEXT</Target> */}
					</Button>
					{!isValid ? (
						<p
							className={`${props.classes.alertMessage} ${props.classes.desktop}`}
						>
							Fill in the details to start the quiz *
						</p>
					) : (
						""
					)}
				</div>
				<div className="col-sm-6" style={{ height: "250px" }}>
					<form
						id="register-form"
						className={props.classes.form}
						onSubmit={handleSubmit}
					>
						<TextInput {...props} decrypted={decryptedString} />
						<MaskingInput {...props} decrypted={decryptedString} />
						<Button
							type="submit"
							className={`${props.classes.button} ${props.classes.mobile}`}
							form="register-form"
							disabled={!isValid}
						>
							NEXT
						</Button>
						{!isValid ? (
							<p
								style={{ color: "#ed1c24", textAlign: "center" }}
								className={`${props.classes.mobile} + ${props.classes.alertMessage}`}
							>
								Fill in the details to start the quiz *
							</p>
						) : (
							""
						)}
					</form>
				</div>
			</div>
		</div>
	);
};

function Registration({ location }) {
	// Code By Pushpanjali
	let history = useHistory();
	const [decrypted, setDecrypted] = useState("");

	useEffect(() => {
		const source = Axios.CancelToken.source();
		function data() {
			let url_data = queryString.parse(location.search).data;

			// console.log(url_data);
			debugger;
			var base64Key = "R2t6MzE5ZkkxMmxPSml2bko4a0tkS2hsTW02RFdqTUc=";
			var key = CryptoJS.enc.Base64.parse(base64Key);

			if (url_data) {
				url_data = url_data.replace(/\s/g, "+");
				var decryptedData = CryptoJS.AES.decrypt(url_data, key, {
					mode: CryptoJS.mode.ECB,
					padding: CryptoJS.pad.Pkcs7,
				});
				var decryptedText = JSON.parse(
					decryptedData.toString(CryptoJS.enc.Utf8)
				);
				// console.log(decryptedText);
			}

			if (decryptedText) {
				setDecrypted(decryptedText);
			}
		}
		// function data() {
		// 	var data = {
		// 		csoemail: "ashish.kumar2@wundermanthompson.com",
		// 		pincode: "600028",
		// 		name: "MAGI BALA ",
		// 		mobile: "9958619444",
		// 		email: "mageshwari.b@doodleblue.com",
		// 	};

		// 	// Encrypt
		// 	var ciphertext = CryptoJS.AES.encrypt(
		// 		JSON.stringify(data),
		// 		"Gkz319fI12lOJivnJ8kKdKhlMm6DWjMG"
		// 	).toString();
		// 	//log encrypted data
		// 	console.log("Encrypt Data -");
		// 	console.log(ciphertext);

		// 	// Decrypt
		// 	var bytes = CryptoJS.AES.decrypt(
		// 		ciphertext,
		// 		"Gkz319fI12lOJivnJ8kKdKhlMm6DWjMG"
		// 	);
		// 	var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

		// 	//log decrypted Data
		// 	console.log("decrypted Data -");
		// 	console.log(decryptedData);
		// }
		data();
		return () => {
			source.cancel();
		};
	}, [location]);

	const validationSchema = yup.object().shape(validationsForm);

	const mapPropsToValues = (values) => {
		return {
			name: values.name || decrypted ? decrypted.name : "",
			email: values.email || decrypted ? decrypted.email : "",
			mobile: values.mobile || decrypted ? decrypted.mobile : "",
			pincode: values.pincode || decrypted ? decrypted.pincode : "",
			csoemail: values.csoemail || decrypted ? decrypted.csoemail : "",
		};
	};

	const FormikForm = withFormik({
		validateOnMount: (props) =>
			validationSchema.isValidSync(mapPropsToValues(props)),
		mapPropsToValues,
		validationSchema,

		handleSubmit: async (values, { setSubmitting }) => {
			// submit to the server
			// values.mobile = values.mobile.replace(/\D/g, "").substr(2);
			if (decrypted.csoemail) {
				values.csoemail = decrypted.csoemail;
			}
			values.pincode = values.pincode.replace(/\D/g, "");
			values.is_registered = 1;
			// console.log(values);

			const response = await Axios.post(config.register_api, values, {
				headers: {
					Authorization: "Basic",
				},
			});

			localStorage.setItem("token", response.data.data.token);
			history.push("/quiz");
			// setSubmitting(false);
		},
	})(withStyles(Styles)(form));

	return <FormikForm />;
}

export default Questions(Registration);
