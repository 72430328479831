import React from "react";
import Swiper from "react-id-swiper";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import SwiperCore, { Navigation } from "swiper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 340,
		margin: "40px 20px",
		height: "auto",
		"& .MuiCardContent-root:last-child": {
			paddingBottom: 10,
		},
		"& .MuiCardContent-root": {
			padding: 10,
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "min-content",
			margin: "auto",
			marginTop: "1rem",
			marginBottom: "1rem",
		},
	},
	container: {
		"& .MuiPaper-elevation1": {
			background: "transparent",
			boxShadow: "none",
		},
	},
	bullet: {
		display: "inline-block",
		margin: "0 6px",
		transform: "scale(0.8)",
		color: "#838383",
	},
	image: {
		width: "100%",
		height: "100%",
		[theme.breakpoints.down("xs")]: {
			width: "341px",
			height: "213px",
		},
	},
	category: {
		fontSize: "12px",
		fontFamily: "NunitoSans-Bold",
		textTransform: "uppercase",
		color: "#ed1c24",
	},
	description: {
		fontSize: "18px",
		color: "#000",
		margin: "20px 0px",
		fontFamily: "NunitoSans-Regular",
	},
	price: {
		fontSize: "18px",
		color: "#000",
		margin: "20px 0px",
		marginRight: 10,
		fontFamily: "NunitoSans-Regular",
	},
	author: {
		fontSize: "12px",
		color: "#838383",
	},
	a: {
		border: "1px solid #ed1c24",
		display: "block",
		width: "max-content",
		padding: "10px 15px",
		textTransform: "uppercase",
		fontSize: "14px",
		color: "#ed1c24",
		fontFamily: "NunitoSans-Bold",
		"&:hover": {
			textDecoration: "none",
			color: "#ed1c24",
		},
	},
	h2: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "24px",
		margin: "2rem 0rem",
		marginRight: "auto",
		marginBottom: 0,
		marginTop: "3rem",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
			textAlign: "center",
			margin: "auto",
			marginBottom: 20,
			marginTop: 20,
		},
	},
}));

SwiperCore.use([Navigation]);

const params = {
	slidesPerView: 3,
	spaceBetween: 30,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
};
function Products(props) {
	const { products } = props;
	const style = useStyles();

	return (
		<div className={style.container + " container"}>
			<h2 className={style.h2}>Related Products</h2>
			<div className="row" style={{ justifyContent: "center" }}>
				<Swiper {...params}>
					{products.map((elem) => (
						<Card className={style.root} key={elem.id}>
							<CardContent>
								<CardMedia
									component="img"
									alt="Blogs"
									height="140"
									image={elem.image}
									title="Blogs"
									className={style.image}
								/>
								<CardContent>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p"
										className={style.description}
									>
										{elem.title}
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p"
										className={style.price}
									>
										<i className="fa fa-rupee" style={{ marginRight: 5 }}></i>
										{elem.price}
									</Typography>
									<a
										href={elem.link}
										className={style.a}
										key={elem.id}
										target="_blank"
										rel="noreferrer"
									>
										BUY NOW
									</a>
								</CardContent>
							</CardContent>
						</Card>
					))}
				</Swiper>
			</div>
		</div>
	);
}

export default Products;
