import React from "react";
import Quiz from "./components/quiz/Quiz";
import Homepage from "./components/homepage/Homepage";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Registration from "./components/Input/Registration";
import UserReport from "./components/userReport/UserReport";

function App() {
	return (
		<div className="main-component">
			<BrowserRouter>
				<Switch>
					{/* <Redirect to="/" from="/quiz" /> */}
					<Route exact path="/thankyou" component={UserReport} />
					<Route exact path="/quiz" component={Quiz} />
					<Route exact path="/register" component={Registration} />
					<Route exact path="/" component={Homepage} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
