import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const Styles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	bottom: {
		color: "#e3e3e3",
	},
	top: {
		color: "#ed1c24",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
	circle: {
		strokeLinecap: "round",
		backgroundColor: "#fde9ea",
	},
	completed: {
		fontSize: "10px",
		fontFamily: "NunitoSans-Bold",
		marginTop: "-6px",
	},
}));

function PercentProgress(props) {
	const classes = Styles();
	const { percent } = props;
	return (
		<>
			<div className={classes.root}>
				<Box position="relative" display="inline-flex">
					<CircularProgress
						variant="determinate"
						className={classes.bottom}
						size={40}
						thickness={1}
						{...props}
						value={100}
					/>
					<CircularProgress
						variant="determinate"
						value={100}
						className={classes.top}
						classes={{
							circle: classes.circle,
						}}
						style={{ background: "#fde9ea", borderRadius: "50%" }}
						size={40}
						thickness={1}
						{...props}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography
							variant="caption"
							component="div"
							color="textSecondary"
							style={{
								fontSize: "11px",
								fontFamily: "NunitoSans-Bold",
								color: "#ed1c24",
							}}
						>{`${Math.round(percent)}%`}</Typography>
					</Box>
				</Box>
			</div>
		</>
	);
}

export default PercentProgress;
