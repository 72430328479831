import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: "#ed1c24",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#ed1c24",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ed1c24",
				borderWidth: "2px",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#ed1c24",
			},
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#ed1c24",
			[theme.breakpoints.down("xs")]: {
				fontSize: 16,
			},
		},
		"& .MuiInputLabel-outlined": {
			// fontSize: 14,
			fontFamily: "NunitoSans-Bold",
			// lineHeight: "30px",
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
		"& .MuiOutlinedInput-input": {
			fontSize: 16,
			fontFamily: "NunitoSans-Regular",
			color: "#000",
			// height: 30,
		},
		"& .MuiFormHelperText-contained": {
			color: "red",
		},
	},
}));

function TextInput(props) {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		decrypted,
	} = props;
	const style = useStyle();

	return (
		<>
			<TextField
				id="name"
				label="NAME *"
				value={values.name}
				onChange={handleChange}
				onBlur={handleBlur}
				helperText={
					touched.name || (errors.name && decrypted) ? errors.name : ""
				}
				error={touched.name && Boolean(errors.name)}
				margin="dense"
				variant="outlined"
				fullWidth
				className={style.root}
			/>
			<TextField
				id="email"
				label="EMAIL *"
				value={values.email}
				onChange={handleChange}
				onBlur={handleBlur}
				helperText={
					touched.email || (errors.email && decrypted) ? errors.email : ""
				}
				error={touched.email && Boolean(errors.email)}
				margin="dense"
				variant="outlined"
				fullWidth
				className={style.root}
			/>
			{/* <input
				type="hidden"
				value="cosemail@gmail.com"
				name="csoemail"
				id="csoemail"
			/> */}
		</>
	);
}

export default TextInput;
