import React from "react";
import imageUrl from "../images/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import ProgressBar from "../components/quiz/ProgressBar";

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
		padding: "1rem",
		boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.06)",
		[theme.breakpoints.down("xs")]: {
			padding: "0.5rem 0rem",
		},
	},
	progressBar: {
		position: "absolute",
		right: 15,
		textAlign: "center",
		top: "-7px",
		[theme.breakpoints.down("xs")]: {
			position: "initial",
			float: "right",
		},
	},
	logo: {
		[theme.breakpoints.down("xs")]: {
			width: "128px",
		},
	},
	headerRow: {
		[theme.breakpoints.down("xs")]: {
			flexWrap: "nowrap",
			alignItems: "center",
		},
	},
	"@global": {
		"*::-webkit-scrollbar": {
			width: "0.4em",
		},
		"*::-webkit-scrollbar-track": {
			"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
		},
	},
}));

function QuestionsHeader(props) {
	const style = styles(props);
	const hyperlink = "https://www.beautifulhomes.com/";
	const { length, indexno, isRegister } = props;

	const calculateProgress = () => {
		const percent = (100 / length) * (length - Math.abs(length - indexno));
		return isNaN(percent) ? 100 : percent;
	};

	return (
		<header className={style.root}>
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className={style.headerRow + " row"}>
							<div className="col-sm-6">
								<a href={hyperlink} target="_blank" rel="noreferrer">
									<img src={imageUrl} alt="Logo" className={style.logo} />
								</a>
							</div>
							<div className="col-sm-6">
								<div className={style.progressBar}>
									{isRegister ? (
										""
									) : (
										<ProgressBar value={calculateProgress()} {...props} />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default QuestionsHeader;
