import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const Footer = () => {
	const useStyles = makeStyles((theme) => ({
		root: {
			fontSize: "10px",
			fontFamily: "NunitoSans-Regular",
		},
		i: {
			fontSize: "10px",
			marginRight: "5px",
		},
		footer: {
			// position: "absolute",
			// bottom: "15px",
			// left: "50%",
			// marginLeft: "-110px",
			textAlign: "center",
			marginTop: "1rem",
			[theme.breakpoints.down("md")]: {
				position: "inherit",
				marginLeft: 0,
				textAlign: "center",
				margin: "10px 0px",
			},
			[theme.breakpoints.down("sm")]: {
				margin: "20px 0px",
			},
			[theme.breakpoints.down("xs")]: {
				position: "inherit",
				marginLeft: 0,
				textAlign: "center",
				margin: "10px 0px",
			},
		},
	}));

	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<h6>
				<i className={classes.i + " fa fa-copyright"} aria-hidden="true"></i>
				<span className={classes.root}>
					Beautiful Homes 2019 | All rights reserved
				</span>
			</h6>
		</footer>
	);
};

export default Footer;
