import React from "react";
import imageUrl from "../images/logo.png";
import { Styles } from "../styles/HomepageStyles";

function Header() {
	const styles = Styles();
	const hyperlink = "https://www.beautifulhomes.com/";

	return (
		<header className={styles.header}>
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<a href={hyperlink} target="_blank" rel="noreferrer">
							<img src={imageUrl} alt="Logo" className={styles.logoWidth} />
						</a>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
