import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 340,
		margin: "40px 20px",
		height: "auto",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "min-content",
			margin: "auto",
			marginTop: "1rem",
			marginBottom: "1rem",
			height: "91%",
		},
		"& .MuiCardActions-root": {
			display: "block",
			padding: 0,
		},
	},
	bullet: {
		display: "inline-block",
		margin: "0 6px",
		transform: "scale(0.8)",
		color: "#838383",
	},
	image: {
		width: "100%",
		height: "100%",
		[theme.breakpoints.down("xs")]: {
			width: "341px",
			height: "213px",
		},
	},
	category: {
		fontSize: "12px",
		fontFamily: "NunitoSans-Bold",
		textTransform: "uppercase",
		color: "#ed1c24",
	},
	description: {
		fontSize: "16px",
		color: "#000",
		margin: "20px 0px",
	},
	author: {
		fontSize: "12px",
		color: "#838383",
	},
	a: {
		"&:hover": {
			textDecoration: "none",
		},
	},
	h2: {
		fontFamily: "NunitoSans-Bold",
		fontSize: "24px",
		margin: "2rem 0rem",
		marginRight: "auto",
		marginBottom: 0,
		marginTop: "3rem",
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
			textAlign: "center",
			margin: "auto",
			marginTop: 20,
		},
	},
}));

function Blogs(props) {
	const { blogs } = props;
	const style = useStyles();
	const bull = <span className={style.bullet}>•</span>;

	return (
		<div className="container">
			<h2 className={style.h2}>Articles that may interest you</h2>
			<div className="row" style={{ justifyContent: "center" }}>
				{blogs.map((elem) => (
					<a
						href={elem.link}
						className={style.a}
						key={elem.id}
						target="_blank"
						rel="noreferrer"
					>
						<Card className={style.root} key={elem.id}>
							<CardActions>
								<CardMedia
									component="img"
									alt="Blogs"
									height="140"
									image={elem.image}
									title="Blogs"
									className={style.image}
								/>
								<CardContent>
									<Typography
										gutterBottom
										variant="h6"
										component="h2"
										className={style.category}
									>
										{elem.category}
										{bull}
										{elem.subcategory}
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p"
										className={style.description}
									>
										{elem.title}
									</Typography>
									<span className={style.author}>{elem.author}</span>
								</CardContent>
							</CardActions>
						</Card>
					</a>
				))}
			</div>
		</div>
	);
}

export default Blogs;
