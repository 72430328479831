import React from "react";
import Buttons from "../Buttons/Buttons";
import Card from "@material-ui/core/Card";
import Radio from "@material-ui/core/Radio";
import Slide from "@material-ui/core/Slide";
import like from "../../../images/like.png";
import liked from "../../../images/liked.png";
import dislike from "../../../images/dislike.png";
import CardMedia from "@material-ui/core/CardMedia";
import disliked from "../../../images/disliked.png";
import { Styles } from "../../../styles/QuizStyles";
import RadioGroup from "@material-ui/core/RadioGroup";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Carousel from "react-material-ui-carousel";

function LikeDislikeButton(props) {
	const style = Styles();
	const { index, clickRef, radioData, questionData, onHandleLDChange } = props;

	return (
		<>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 500 } : {})}
			>
				<div className="col-sm-4">
					<span className={style.span}>
						Question
						<span className={style.questionCount}>
							{index + 1} / {questionData.length}
						</span>
					</span>
					<h1
						className={style.h1}
						dangerouslySetInnerHTML={{ __html: radioData.question }}
					></h1>
					<p
						className={style.p}
						dangerouslySetInnerHTML={{ __html: radioData.subhead }}
					></p>
					<div>
						<Buttons
							{...props}
							style={style}
							likeDislike={true}
							btnData={radioData}
							className={style.desktop}
						/>
					</div>
				</div>
			</Slide>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 1000 } : {})}
			>
				<div className={"col-sm-8"}>
					<div
						className={style.cardContainer}
						id="carouselLD"
						// className={
						// 	(console.log(isPrevious),
						// 	likeDislikeSelected.length === 0
						// 		? isPrevious
						// 			? style.cardContainer
						// 			: `${style.disable} + ${style.cardContainer}`
						// 		: style.cardContainer)
						// }
					>
						<Carousel
							animation="slide"
							timeout={300}
							interval={2000}
							autoPlay={false}
							navButtonsAlwaysVisible={true}
							indicators={false}
							className={style.carouselLD}
							ref={clickRef}
						>
							{radioData.options
								.sort((a, b) => b.id - a.id)
								.map((elem, i) => {
									return (
										<Card
											style={{
												maxWidth: 375,
												// position: "absolute",
												zIndex: i,
												background: "#f8f4f4",
												margin: "auto",
												// top: i + 10 + "%",
											}}
											key={elem.id}
											// className={
											// 	elem.likeChecked
											// 		? lastQuestion === "true"
											// 			? style.backLeft
											// 			: style.rotateLeft
											// 		: elem.dislikeChecked
											// 		? lastQuestion === "true"
											// 			? style.backRight
											// 			: style.rotateRight
											// 		: ""
											// }
										>
											<CardContent>
												<CardMedia
													data-src={elem.option}
													image={elem.option}
													title="Like and dislike"
													className={style.likeDislikeImage}
												/>
											</CardContent>
											<CardActions>
												<RadioGroup style={{ display: "block" }}>
													<FormControlLabel
														value="like"
														control={
															<Radio
																id={elem.id.toString()}
																value="like"
																checked={elem.likeChecked}
																onChange={onHandleLDChange}
																style={{
																	display: "none",
																}}
															/>
														}
														label={
															<img
																src={elem.likeChecked ? liked : like}
																alt="Like"
																className={style.likeDislikeLabel}
															/>
														}
													/>
													<FormControlLabel
														value="dislike"
														control={
															<Radio
																id={elem.id.toString()}
																value="dislike"
																checked={elem.dislikeChecked}
																onChange={onHandleLDChange}
																style={{ display: "none" }}
															/>
														}
														label={
															<img
																src={elem.dislikeChecked ? disliked : dislike}
																alt="Dislike"
																className={style.likeDislikeLabel}
															/>
														}
													/>
												</RadioGroup>
											</CardActions>
										</Card>
									);
								})}
						</Carousel>
					</div>
					{/* {likeDislikeSelected.length === 0 ? (
						isPrevious ? (
							<Dialog
								open={
									lastQuestion === "true"
										? true
											? openDialogue === "true"
												? openup
													? true
													: false
												: false
											: openDialogue === "true"
											? true
											: false
										: false
								}
								aria-labelledby="responsive-dialog-title"
								className={style.modal}
							>
								<DialogContent>
									<DialogTitle className={style.modalHeader}>
										Want to choose a different option?
									</DialogTitle>
								</DialogContent>
								<DialogActions>
									<Button autoFocus onClick={onHandleReanswer} color="primary">
										Yes
									</Button>
									<Button onClick={onhandleClose} color="primary" autoFocus>
										No
									</Button>
								</DialogActions>
							</Dialog>
						) : (
							""
						)
					) : (
						""
					)} */}
					<Buttons
						{...props}
						style={style}
						likeDislike={true}
						btnData={radioData}
						className={style.mobile}
					/>
				</div>
			</Slide>
		</>
	);
}

export default LikeDislikeButton;
