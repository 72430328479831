import React from "react";
import CarouselTemplate from "../../template/CarouselType/carouselTemplate";

function Slider() {
	var items = [
		{
			imageUrl: require("../../images/contemporary.png").default,
			mobileUrl: require("../../images/image1-mobile.png").default,
		},
		{
			imageUrl: require("../../images/homepage-banner2.png").default,
			mobileUrl: require("../../images/image2-mobile.png").default,
		},
		{
			imageUrl: require("../../images/homepage-banner3.png").default,
			mobileUrl: require("../../images/image3-mobile.png").default,
		},
	];
	return <CarouselTemplate item={items} type="image" autoPlay={true} />;
}

export default Slider;
