import React from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import QuestionsHeader from "../../common/QuestionsHeader";

const Styles = makeStyles((theme) => ({
	main: {
		position: "absolute",
		top: "35%",
		left: "12%",
		[theme.breakpoints.down("xs")]: {
			position: "inherit",
			top: 0,
			left: 0,
			marginTop: 20,
		},
	},
}));

const Questions = (Component) => {
	// Code By Pushpanjali
	document.body.style.backgroundColor = "#f8f4f4";
	const Question = (props) => {
		const styl = Styles();
		return (
			<>
				<QuestionsHeader isRegister={true} />
				<Slide
					in={true}
					mountOnEnter
					direction="up"
					unmountOnExit
					{...(true ? { timeout: 500 } : {})}
					style={{ transformOrigin: "0 0 0" }}
				>
					<main className={styl.main + " container"} id="questions">
						<div className="row">
							<Component {...props} />
						</div>
					</main>
				</Slide>
			</>
		);
	};
	return Question;
};

export default Questions;
