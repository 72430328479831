import React from "react";
import Chart from "./Chart";
import tipImage from "../../images/tip.png";
import PercentProgress from "./PercentProgress";
import { Styles } from "../../styles/ReportStyles";

function HighReportData(props) {
	const style = Styles();
	const { reportData, barOptions, barData, chartRef } = props;

	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="row">
					<div className="col-sm-8">
						<div className="thankyou-text">
							<h4 className={style.h4}>Thank you! Your results are here.</h4>
							<p className={style.p}>
								Psst: Our design team has also received your detailed report to
								further help you.
							</p>
						</div>
					</div>
				</div>
				<div className={style.row + " row"} style={{ alignItems: "center" }}>
					<div className="col-sm-8">
						<div className="high-style">
							<div className={style.title}>
								<PercentProgress percent={reportData[0].value} />
								<h1 className={style.h1}>
									Your home décor style is{" "}
									<span className={style.span}>{reportData[0].style}</span>
								</h1>
							</div>
							<div className="chart">
								<Chart
									barOptions={barOptions}
									barData={barData}
									chartRef={chartRef}
								/>
								<p className={style.highDescription}>
									{reportData[0].description}
								</p>
							</div>
						</div>
					</div>
					<div className="col-sm-4">
						<div className={style.grid}>
							<div>
								<img
									src={reportData[0].image}
									className={style.imageWidth}
									alt="Styles"
								/>
							</div>
							<div className={style.styleTip}>
								<div className={style.alignCenter}>
									<img
										src={tipImage}
										className={style.tipImage}
										alt="Style tip"
									/>
									<div
										style={{
											fontSize: "10px",
											fontFamily: "RobotoSlab-Bold",
											color: "#ed1c24",
											marginTop: 10,
										}}
									>
										Style Tip
									</div>
								</div>
								<div style={{ display: "block", marginTop: 10 }}>
									<p className={style.styleTipDesc}>
										{reportData[0].style_tip}
									</p>
									<p className={style.styleTipDesc}>
										{reportData[0].additional_style_tip}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HighReportData;
