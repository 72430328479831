import {
	Document,
	Page,
	Text,
	Image,
	View,
	StyleSheet,
	Font,
	pdf,
} from "@react-pdf/renderer";
import tipImage from "../../../images/tip.png";
import imageUrl from "../../../images/logo.png";
import font from "../../../fonts/RobotoSlab-Bold.woff";
import NBfont from "../../../fonts/NunitoSans-Bold.woff";
import NRfont from "../../../fonts/NunitoSans-Regular.woff";

Font.register({ family: "RobotoSlab-Bold", src: font });
Font.register({ family: "NunitoSans-Bold", src: NBfont });
Font.register({ family: "NunitoSans-Regular", src: NRfont });

const styles = StyleSheet.create({
	page: { backgroundColor: "#f8f4f4" },
	section: { color: "white", textAlign: "center", margin: 30 },
	logo: {
		width: "120px",
		height: "25px",
		textAlign: "center",
		margin: "auto",
		marginTop: "10px",
		marginBottom: "10px",
		boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.06)",
	},
	chartImage: {
		width: 400,
		height: 200,
		textAlign: "left",
		marginRight: "auto",
		marginLeft: -60,
	},
	circle: {
		width: 30,
		height: 30,
		borderRadius: 30,
		border: "1 solid #ed1c24",
		backgroundColor: "#fde9ea",
		textAlign: "center",
		marginRight: 10,
	},
	percent: {
		fontSize: 9,
		color: "#ed1c24",
		textAlign: "center",
		margin: "auto",
		alignItems: "center",
		display: "flex",
		marginLeft: "-3px",
	},
	table: {
		display: "flex",
		justifyContent: "flex-start",
		alignContent: "stretch",
		flexWrap: "nowrap",
		alignItems: "stretch",
		marginLeft: 10,
		marginBottom: 30,
		marginTop: 20,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignContent: "stretch",
		flexWrap: "nowrap",
		alignItems: "center",
		flexBasis: 35,
	},
	tipRow: {
		display: "flex",
		flexDirection: "row",
		alignContent: "stretch",
		flexWrap: "nowrap",
		alignItems: "center",
		flexBasis: 35,
		backgroundColor: "#fff",
		marginRight: 15,
		marginTop: -20,
		borderRadius: 10,
	},
	substyleRow: {
		display: "flex",
		flexDirection: "row",
		alignContent: "stretch",
		flexWrap: "nowrap",
		alignItems: "baseline",
		flexBasis: 35,
		width: 280,
		marginTop: -20,
	},
	h1: {
		fontFamily: "RobotoSlab-Bold",
	},
	shift: {
		marginLeft: 10,
	},
	description: {
		fontSize: 12,
		width: 280,
		fontFamily: "NunitoSans-Regular",
		marginLeft: 10,
		textAlign: "justify",
	},
	highestStyleImg: {
		width: 350,
		height: 418,
	},
	styleTip: {
		fontSize: 8,
		width: 120,
		fontFamily: "NunitoSans-Regular",
		// marginLeft: "auto",
		marginTop: 10,
		marginBottom: 10,
		paddingLeft: 5,
		paddingRight: 15,
	},
	styleTip2: {
		fontSize: 8,
		width: 150,
		fontFamily: "NunitoSans-Regular",
		// marginLeft: "auto",
		marginTop: 0,
		marginBottom: 10,
		paddingLeft: 5,
		paddingRight: 15,
	},
	tip: {
		width: 18,
		height: 18,
		margin: "auto",
		marginRight: 15,
	},
	tipText: {
		fontSize: 8,
		fontFamily: "RobotoSlab-Bold",
		color: "#ed1c24",
		marginTop: 5,
	},
	tipWrapper: {
		marginRight: 10,
		paddingLeft: 10,
	},
	subStyle: {
		fontSize: 10,
		fontFamily: "RobotoSlab-Bold",
		marginLeft: 20,
		marginTop: 30,
	},
	h6: {
		fontFamily: "NunitoSans-Bold",
		fontSize: 10,
		textTransform: "capitalize",
		marginBottom: 5,
	},
	p: {
		width: 220,
		fontFamily: "NunitoSans-Regular",
		fontSize: 10,
		textAlign: "justify",
	},
});

const MyDocument = (props) => (
	<Document>
		<Page size="A4" style={styles.page}>
			<View style={{ backgroundColor: "#fff" }}>
				<Image src={imageUrl} style={styles.logo} />
			</View>
			<View style={styles.table}>
				<View style={styles.row}>
					<View>
						<View style={styles.table}>
							<View style={styles.row}>
								<View style={styles.circle}>
									<Text style={styles.percent}>
										{Math.round(props.reportData[0].value) + "%"}
									</Text>
								</View>
								<Text style={styles.h1}>
									Your home décor style is{" "}
									<Text style={{ textTransform: "lowercase" }}>
										{props.reportData[0].style}
									</Text>
								</Text>
							</View>
						</View>
						<View style={styles.shift}>
							<Image src={props.chartImage} style={styles.chartImage} />
						</View>
						<View>
							<Text style={styles.description}>
								{props.reportData[0].description}
							</Text>
						</View>
					</View>
					<View style={{ flex: 1, marginTop: 40 }}>
						<Image
							src={props.reportData[0].image}
							style={{
								width: "auto",
								marginRight: 15,
								borderRadius: 10,
							}}
						/>
						<View style={styles.tipRow}>
							<View style={styles.tipWrapper}>
								<Image src={tipImage} style={styles.tip} />
								<Text style={styles.tipText}>Style Tip</Text>
							</View>
							<View style={{ display: "block" }}>
								<Text style={styles.styleTip}>
									{props.reportData[0].style_tip}
								</Text>
								<Text style={styles.styleTip2}>
									{props.reportData[0].additional_style_tip}
								</Text>
							</View>
						</View>
					</View>
				</View>
			</View>
			<View>
				<Text style={styles.subStyle}>Your other style preferences</Text>
			</View>
			<View style={styles.line}></View>
			<View style={styles.table}>
				<View style={styles.row}>
					{props.reportData.slice(1).map((elem, i) => {
						return (
							<View key={elem.value}>
								<View style={styles.table}>
									<View style={styles.substyleRow}>
										<View style={styles.circle}>
											<Text style={styles.percent}>
												{Math.round(elem.value) + "%"}
											</Text>
										</View>
										<View>
											<Text style={styles.h6}>{elem.style}</Text>
											<Text style={styles.p}>{elem.description}</Text>
										</View>
									</View>
								</View>
							</View>
						);
					})}
				</View>
			</View>
		</Page>
	</Document>
);

export default MyDocument;
