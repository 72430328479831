import React from "react";

function Buttons(props) {
	const {
		style,
		btnData,
		onButtonClick,
		onButtonPreviousClick,
		selectedCount,
		clickRef,
		className,
	} = props;

	return (
		<div>
			{btnData.firstQuestion === true ? (
				<div>
					<button
						className={`${style.button} ${className}`}
						onClick={() => onButtonClick()}
						disabled={
							btnData.min === 0
								? selectedCount > btnData.min
									? false
									: true
								: selectedCount < btnData.min || selectedCount > btnData.min
								? true
								: false
						}
					>
						Next
					</button>
				</div>
			) : (
				<div className={style.flex}>
					<button
						onClick={() => onButtonPreviousClick()}
						className={`${style.previousBtn} ${className}`}
					>
						Previous
					</button>
					<button
						ref={clickRef}
						className={`${style.button} ${className}`}
						onClick={() => onButtonClick()}
						disabled={
							btnData.type === 1
								? btnData.min === 0
									? selectedCount > btnData.min
										? false
										: true
									: selectedCount < btnData.min || selectedCount > btnData.min
									? true
									: false
								: btnData.type !== 2
								? selectedCount <= btnData.min
									? true
									: false
								: btnData.options.length > selectedCount
								? true
								: false
						}
					>
						Next
					</button>
				</div>
			)}
		</div>
	);
}

export default Buttons;
