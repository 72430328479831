import React from "react";
import PercentProgress from "./PercentProgress";
import { Styles } from "../../styles/ReportStyles";

function LowReportData(props) {
	const { reportData } = props;
	const style = Styles();
	return (
		<div className="row">
			<div className={style.borderLine + " col-sm-12"}>
				<h6 className={style.subStyleHeading}>Your other style preferences</h6>
				<div className="row">
					{reportData.slice(1).map((elem) => {
						return (
							<div
								className="col-sm-6"
								style={{ paddingTop: "1rem" }}
								key={elem.value}
							>
								<div style={{ display: "flex" }}>
									<PercentProgress percent={elem.value} />
									<div style={{ marginLeft: "20px" }}>
										<span className={style.styleTitle}>{elem.style}</span>
										<p className={style.styleDescription}>{elem.description}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default LowReportData;
