import React, { useEffect } from "react";
import Buttons from "../Buttons/Buttons";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import { Styles } from "../../../styles/QuizStyles";
import checkedImage from "../../../images/checked.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function ImageCheckbox(props) {
	const {
		checkboxData,
		onCheckBoxChange,
		index,
		questionData,
		selectedCount,
	} = props;
	const style = Styles();
	const reff = React.useRef("");
	const [scrollToEnd, setScrollToEnd] = React.useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (
				reff.current.scrollTop ===
				reff.current.scrollHeight - reff.current.offsetHeight
			) {
				setScrollToEnd(true);
			} else {
				setScrollToEnd(false);
			}
		};
		document
			.getElementById("scrollDiv")
			.addEventListener("scroll", handleScroll, { passive: true });
	}, []);

	return (
		<>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 500 } : {})}
			>
				<div className="col-sm-4">
					<span className={style.span}>
						Question
						<span className={style.questionCount}>
							{index + 1} / {questionData.length}
						</span>
					</span>
					<h1
						className={style.h1}
						dangerouslySetInnerHTML={{ __html: checkboxData.question }}
					></h1>
					<p
						className={style.p}
						dangerouslySetInnerHTML={{ __html: checkboxData.subhead }}
					></p>
					<div>
						<Buttons
							style={style}
							btnData={checkboxData}
							{...props}
							className={style.desktop}
						/>
					</div>
				</div>
			</Slide>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
				style={{ transformOrigin: "0 0 0" }}
				{...(true ? { timeout: 1000 } : {})}
			>
				<div className={style.mansonry + " col-sm-8"} id="scrollDiv" ref={reff}>
					<ResponsiveMasonry
						columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
					>
						<div>
							<Masonry columnsCount={2} gutter="15px">
								{checkboxData.options.map((elem) => (
									<FormControlLabel
										key={elem.id}
										disabled={
											checkboxData.min !== 0
												? selectedCount === checkboxData.min
													? !elem.checked
														? true
														: false
													: false
												: false
										}
										control={
											<Checkbox
												value={elem.id}
												className={style.checkboxNone}
												checked={elem.checked}
												onChange={onCheckBoxChange}
											/>
										}
										label={
											<>
												<img
													src={elem.option}
													alt="Images"
													id={elem.id}
													style={{
														width: "100%",
														display: "block",
														cursor: "pointer",
														borderRadius: 5,
													}}
												/>
												<div
													className={
														elem.checked ? style.backdropShow : style.backdrop
													}
												>
													<img
														src={checkedImage}
														alt="Checked Option"
														className={style.checkedImage}
													/>
												</div>
											</>
										}
									/>
								))}
							</Masonry>
						</div>
					</ResponsiveMasonry>
					<div>
						<Buttons
							style={style}
							btnData={checkboxData}
							{...props}
							className={style.mobile}
						/>
					</div>
				</div>
			</Slide>
			{checkboxData.options.length > 3 && !scrollToEnd ? (
				<div className={style.scroll}>
					<div>Scroll</div>
					<i
						className={style.scrollArrow + " fa fa-angle-down"}
						aria-hidden="true"
					></i>
				</div>
			) : (
				""
			)}
		</>
	);
}

export default ImageCheckbox;
