import React from "react";
import ImageCheckbox from "./ImageCheckbox";
import TextCheckbox from "./TextCheckbox";

function Checkbox(props) {
	const { checkboxData } = props;
	return (
		<>
			{checkboxData.format === 1 ? (
				<ImageCheckbox {...props} />
			) : (
				<TextCheckbox {...props} />
			)}
		</>
	);
}

export default Checkbox;
