import React from "react";
import { Link } from "react-router-dom";
import { Styles } from "../../styles/HomepageStyles";

function Content() {
	const classes = Styles();
	const points = [
		{
			id: 1,
			tag: "Fill In Your Details & Answer The Questions",
		},
		{
			id: 2,
			tag: "Get Your Style Report / Moodboard!",
		},
		{
			id: 3,
			tag: "Inspire the Designer with your choices",
		},
	];
	return (
		<div className={classes.content + " content-wrapper"}>
			<h1 className={classes.h1}>
				Not sure if you are eclectic, contemporary, or traditional? Take the
				Beautiful Homes decor quiz to discover your home decor aesthetic.
			</h1>
			<p className={classes.p}>
				At the end of the quiz, you’ll receive a downloadable style report /
				moodboard. You can also share your results with your friends.
			</p>
			<div className="pointer-wrapper">
				{points.map((elem) => (
					<div className={classes.pointers} key={elem.id}>
						<span className={classes.points}>{elem.id}</span>
						<h6 className={classes.pointerFont}>{elem.tag}</h6>
					</div>
				))}
			</div>
			<div>
				<Link to="/register" className={classes.button}>
					discover my style
				</Link>
			</div>
		</div>
	);
}

export default Content;
