import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
	root: {
		// "& div:last-child": {
		// 	[theme.breakpoints.down("sm")]: {
		// 		bottom: 55,
		// 	},
		// },
		"& .MuiSvgIcon-root": {
			color: "#fff",
			width: "14px",
			height: "14px",
			margin: "0px 3px",
			[theme.breakpoints.down("sm")]: {
				width: "10px",
				height: "10px",
				margin: "0px 2px",
			},
		},
		"& .Carousel-active-20": {
			color: "#ed1c24",
		},
		"& .MuiPaper-elevation1": {
			boxShadow: "none",
		},
		"& .Carousel-buttonWrapper-21:hover .Carousel-button-23": {
			filter: "none",
			backgroundColor: "#ed1c24",
		},
		"& .Carousel-button-23": {
			backgroundColor: "transparent",
		},
	},
}));
